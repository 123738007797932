<div data-pages="parallax">
  <div class="container no-padding container-fixed-lg">
    <div class="inner">
      <!-- START BREADCRUMB -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/users">Users</a></li>
        <li class="breadcrumb-item active">{{formType | titlecase}}</li>
      </ol>
    </div>
  </div>
</div>

<div class="container no-padding">
  <!-- START CARD -->
  <div data-pages="card" class="card card-default">
    <div class="card-header">
      <div class="card-title">
        {{formType | titlecase}} User
      </div>
      <div class="card-controls">
        <ul>
        </ul>
      </div>
    </div>
    <div class="card-block">
      <form [formGroup]="formGroup">
        <div class="form-group required">
          <label class="control-label">ID</label>
          <input type="text" class="form-control" formControlName="id" />
        </div>
        <div class="form-group required">
          <label class="control-label">Type</label>
          <select class="form-control" formControlName="type">
            <option [value]="'MALE'">Male</option>
            <option [value]="'FEMALE'">Female</option>
            <option [value]="'STAFF'">Staff</option>
          </select>
        </div>
        <div class="checkbox check-success">
          <input type="checkbox" value="1" id="checkbox2" formControlName="isAvailable">
          <label for="checkbox2">Is Activated</label>
        </div>
      </form>
      
    </div>
  </div>
  
  <!-- END CARD -->
  
  <div class="m-t-10 text-right">
    <button class="btn btn-success" (click)="onSubmit()" [disabled]="formGroup.invalid">{{formType | titlecase}} User</button>
  </div>
</div>
