<div data-pages="parallax">
  <div class="container no-padding container-fixed-lg">
    <div class="inner">
      <!-- START BREADCRUMB -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/users">Settings</a></li>
        <li class="breadcrumb-item active">My Account</li>
      </ol>
    </div>
  </div>
</div>

<div class="container no-padding">
  <!-- START CARD -->
  <div data-pages="card" class="card card-default">
    <div class="card-header">
      <div class="card-title">
        My Account
      </div>
      <div class="card-controls">
        <ul>
        </ul>
      </div>
    </div>
    <div class="card-block">
      <form [formGroup]="formGroup">
        <div class="form-group required">
          <label class="control-label">Username</label>
          <input type="text" class="form-control" formControlName="username" />
        </div>
        <div class="form-group required">
          <label class="control-label">Password</label>
          <input type="password" class="form-control" formControlName="password" placeholder="**************" />
        </div>
      </form>

      <div class="text-primary semi-bold fs-14" *ngIf="hasUpdated">
        Your account details have been updated. Please log in again. <br/>
        ... Returning to Login Page
      </div>
      
    </div>
  </div>
  
  <!-- END CARD -->
  
  <div class="m-t-10 text-right">
    <button class="btn btn-success" (click)="onSubmit()" [disabled]="formGroup.invalid">Update Account</button>
  </div>
</div>
