<!-- START FOOTER -->
<div class="container container-fixed-lg footer">
  <div class="copyright sm-text-center">
    <p class="small no-margin pull-left sm-pull-reset">
      <span class="hint-text">Copyright © 2014</span>
      <span class="font-montserrat">REVOX</span>.
      <span class="hint-text">All rights reserved.</span>
      <span class="sm-block"><a href="#" class="m-l-10 m-r-10">Terms of use</a> | <a href="#" class="m-l-10">Privacy Policy</a>
      </span>
    </p>
    <p class="small no-margin pull-right sm-pull-reset">
      <a href="#">Hand-crafted</a>
      <span class="hint-text">&amp; Made with Love ®</span>
    </p>
    <div class="clearfix"></div>
  </div>
</div>
<!-- END FOOTER -->
