<!-- MODAL STICK UP  -->
<div class="modal" [ngClass]="{'disable-scroll show': visible}" [ngStyle]="{'display': 'block'}" *ngIf="visible" [@dialog]>
  <div class="modal-dialog" [ngClass]="getModalSize()">
    <div class="modal-content">
      <div class="modal-header clearfix text-left">
        <button type="button" class="close" (click)="close()">
          <i class="pg-close fs-14"></i>
        </button>
        <h5>{{header || 'Modal'}}</h5>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- END MODAL STICK UP  -->


<div class="modal-backdrop fade show" *ngIf="visible" [@fadeOut] (click)="backdropClose()"></div>