<div data-pages="parallax">
  <div class="container no-padding container-fixed-lg">
    <div class="inner">
      <!-- START BREADCRUMB -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/users">Users</a></li>
        <li class="breadcrumb-item active">All</li>
      </ol>
    </div>
  </div>
</div>

<div class="container no-padding">
  <!-- START CARD -->
  <div data-pages="card" class="card card-default">
    <div class="card-header  ">
      <div class="card-title">
        Users
      </div>
      <div class="card-controls">
        <ul>
          <li>
            <button class="btn btn-sm btn-success" routerLink="add">Add</button>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-block">
      <div class="form-group m-b-0 m-t-20">
        <label>Filter Table</label> 
      </div>
      <div class="btn-group">
        <label class="btn btn-primary" [ngClass]="{'active': tableFilter === 'ACTIVATED'}">
          <input type="radio" name="tableFilter" (click)="filterChange('ACTIVATED')"> Activated
        </label>
        <label class="btn btn-primary" [ngClass]="{'active': tableFilter === 'DEACTIVATED'}">
          <input type="radio" name="tableFilter" (click)="filterChange('DEACTIVATED')"> Deactivated
        </label>
        <label class="btn btn-primary" [ngClass]="{'active': tableFilter === 'ALL'}">
          <input type="radio" name="tableFilter" (click)="filterChange('ALL')"> Both
        </label>
      </div>
      <div class="m-t-20">        
        <table class="table table-condensed table-responsive-block" 
          datatable 
          [dtTrigger]="dtTrigger" 
          [dtOptions]="dtOptions"
          style="width: 100%"
          >
          <!-- <thead>
            <tr>
              <th>ID</th>
              <th>Account Type</th>
              <th width="20%">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of usersList">
              <td>{{user.id}}</td>
              <td>{{user.type}}</td>
              <td class="text-left">
                <a class="cursor m-b-5" routerLink="/users/report/{{user.id}}">
                  <i class="fa fa-file-text"></i>
                  View Report
                </a>
                <a class="cursor m-b-5" routerLink="/users/edit/{{user.id}}">
                  <i class="fa fa-pencil"></i>
                  Edit
                </a>
                <a class="cursor text-danger" (click)="onDeleteUser(user)" *ngIf="user.isAvailable === 1">
                  <i class="fa fa-trash"></i>
                  Delete
                </a>
              </td>
            </tr>
          </tbody> -->
        </table>
      </div>
    </div>
  </div>
  <!-- END CARD -->
</div>

<app-modal [(visible)]="deleteModal" [header]="'Delete User'" [modalSize]="'md'">
  Are you sure you want to delete {{userToBeDeleted?.id}}?
  <div class="text-center m-t-15">
    <button class="btn btn-danger" (click)="deleteUser()">Yes, Delete it</button>
    <button class="btn btn-info" (click)="deleteModal = false">No</button>
  </div>
</app-modal>