<body class="horizontal-menu horizontal-app-menu" [ngClass]="{'fixed-header': activateFixed}" (window:resize)="onResize($event)">
  <app-header></app-header>
  <!-- START PAGE-CONTAINER -->
  <div class="page-container">
    <!-- START PAGE CONTENT WRAPPER -->
    <div class="page-content-wrapper">
      <div class="content">
        <router-outlet></router-outlet>
      </div>
      <!-- END PAGE CONTENT -->
      <br/>
      <app-footer></app-footer>
    </div>
    <!-- END PAGE CONTENT WRAPPER -->
  </div>
  <!-- END PAGE CONTAINER -->
</body>
