<div class="login-wrapper">
  <!-- START Login Right Container-->
  <div class="login-container bg-white">
    <div class="p-l-50 m-l-20 p-r-50 m-r-20 p-t-50 sm-p-l-15 sm-p-r-15 sm-p-t-40">
      <img src="assets/img/logo.png" alt="logo" data-src="assets/img/logo.png" data-src-retina="assets/img/logo_2x.png" width="78">
      <span class="h4" style="display: inline-block; vertical-align: middle">City Wash Cloud</span>
      <p class="p-t-35">Sign into your City Wash Cloud account</p>
      <!-- START Login Form -->
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="p-t-15" role="form">
        <!-- START Form Control-->
        <div class="form-group form-group-default">
          <label>Login</label>
          <div class="controls">
            <input type="text" name="username" placeholder="User Name" class="form-control" formControlName="username">
          </div>
        </div>
        <!-- END Form Control-->
        <!-- START Form Control-->
        <div class="form-group form-group-default">
          <label>Password</label>
          <div class="controls">
            <input type="password" class="form-control" name="password" placeholder="Credentials" formControlName="password">
          </div>
        </div>
        <!-- START Form Control-->
        <!-- <div class="row">
          <div class="col-md-6 no-padding sm-p-l-10">
            <div class="checkbox ">
              <input type="checkbox" value="1" id="checkbox1">
              <label for="checkbox1">Keep Me Signed in</label>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-center justify-content-end">
            <a href="#" class="text-info small">Help? Contact Support</a>
          </div>
        </div> -->
        <!-- END Form Control-->
        <div class="text-danger mb-3">{{error}}</div>     
        <button class="btn btn-info btn-cons m-t-10" type="submit">Sign in</button>
      </form>
      <!--END Login Form-->
    </div>
  </div>
  <!-- END Login Right Container-->