<!-- START HEADER -->
<div class="header">
  <div class="container">
    <div class="header-inner header-sm-height p-t-15">

    </div>
    <div class="header-inner justify-content-between header-lg-height title-bar">
      <div class="d-flex align-items-center">
        <a href="#" class="btn-link toggle-sidebar hidden-lg-up pg pg-menu m-r-15" data-toggle="horizontal-menu">
            </a>
        <div class="brand inline align-self-end">
          <span class="fs-16 bold font-montserrat text-white">CW</span>
        </div>
        <h2 class="page-title align-self-end hidden-md-down">
          City Wash Cloud
        </h2>
      </div>

      <!-- START User Info-->
      <div class="d-flex align-items-center">
        <div class="dropdown pull-right sm-m-r-5">
          <button class="profile-dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="pull-left p-r-10 fs-14 font-heading" style="color: #788195">
              <a class="cursor semi-bold">{{user?.username}}</a>
              <i class="fa fa-caret-down" aria-hidden="true"></i>              
            </div>
          </button>
          <div class="dropdown-menu dropdown-menu-right profile-dropdown" role="menu">
            <a routerLink="/settings/account" class="dropdown-item"><i class="pg-settings_small"></i> Account Settings</a>
            <!-- <a href="#" class="dropdown-item"><i class="pg-outdent"></i> Feedback</a> -->
            <!-- <a href="#" class="dropdown-item"><i class="pg-signals"></i> Help</a> -->
            <a (click)="logout()" class="clearfix bg-master-lighter dropdown-item">
                    <span class="pull-left">Logout</span>
                    <span class="pull-right"><i class="pg-power"></i></span>
                  </a>
          </div>
        </div>
      </div>
      <!-- END User Info-->
    </div>
    <div class="menu-bar header-sm-height" data-pages-init='horizontal-menu' data-hide-extra-li="4">
      <a href="#" class="btn-link toggle-sidebar hidden-lg-up pg pg-close" data-toggle="horizontal-menu">
      </a>
      <ul>
        <ng-container *ngFor="let page of pages; let i = index">
          <li *ngIf="page.type === 'single'" routerLinkActive="active">
            <a [routerLink]="['/', page.path]">
              <span class="title">{{page.name}}</span>
            </a>
          </li>
          <li *ngIf="page.type === 'multi'" routerLinkActive="active">
            <a href="javascript:;">
              <span class="title">{{page.name}}</span>
              <span class=" arrow"></span>
            </a>
            <ul class="">
              <li class="" *ngFor="let subPage of page.subPages" routerLinkActive="active">
                <a [routerLink]="['/', page.path, subPage.path]">{{subPage.name}}</a>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
