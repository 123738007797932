<div data-pages="parallax">
  <div class="container no-padding container-fixed-lg">
    <div class="inner">
      <!-- START BREADCRUMB -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/products">Products</a></li>
        <li class="breadcrumb-item active">All</li>
      </ol>
    </div>
  </div>
</div>

<div class="container no-padding">
  <!-- START CARD -->
  <div data-pages="card" class="card card-default">
    <div class="card-header  ">
      <div class="card-title">
        Products
      </div>
      <div class="card-controls">
        <ul>
          <li>
            <button class="btn btn-sm btn-success" routerLink="add">Add</button>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-block">
      <!-- Nav tabs -->
      <div class="m-t-20">
        <p class="m-b-10">Click and drag rows to reorder the products.</p>
        <table class="table table-condensed">
          <thead>
            <tr>
              <th width="120px">Sorting</th>
              <th width="120px">Product Image</th>
              <th>Product Name</th>
              <th>Chinese Name</th>
              <th width="20%">Actions</th>
            </tr>
          </thead>
          <tbody [sortablejs]="productsList" [sortablejsOptions]="sortablejsOptions">
            <tr *ngFor="let product of productsList; let i = index">
              <td class="text-center">
                {{i + 1}}
              </td>
              <td>
                <ng-container *ngIf="product.image; else noProductImage">
                  <img [src]="parseImage(product.image)" class="img-fluid" width="120px" />
                </ng-container>
                <ng-template #noProductImage>
                  No Image Available
                </ng-template>
              </td>
              <td>{{product.name || '-'}}</td>
              <td>{{product.chineseName || '-'}}</td>
              <td class="text-left">
                <a class="cursor m-b-5" routerLink="/products/edit/{{product.idProduct}}">
                  <i class="fa fa-pencil"></i> Edit Product
                </a>
                <a class="cursor text-danger" (click)="onDeleteProduct(product)">
                  <i class="fa fa-trash"></i> Delete Product
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- END CARD -->
</div>

<app-modal [(visible)]="showDeleteProductModal" [header]="'Delete ' + productToBeDeleted?.name" [modalSize]="'md'">
  Are you sure you want to delete {{productToBeDeleted?.name}}?
  <div class="text-center m-t-15">
    <button class="btn btn-danger" (click)="deleteProduct()">Yes, Delete it</button>
    <button class="btn btn-info" (click)="showDeleteProductModal = false">No</button>
  </div>
</app-modal>