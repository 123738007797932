<!-- START CARD -->
<div data-pages="card" class="card card-default">
  <div class="card-header  ">
    <div class="card-title">
      Select Dates
    </div>
    <div class="card-controls">
      <ul>
        <li>
          <!-- <button class="btn btn-sm btn-success" routerLink="add">Add</button> -->
        </li>
      </ul>
    </div>
  </div>
  <div class="card-block">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group" [ngClass]="{'has-error': this.form.get('startDate').errors}">
            <label class="control-label">Start Date</label>
            <input type="date" class="form-control" formControlName="startDate" />
            <span class="text-danger" *ngIf="this.form.get('startDate').errors">
              Invalid date
            </span>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group" [ngClass]="{'has-error': this.form.get('endDate').errors}">
            <label class="control-label">End Date</label>
            <input type="date" class="form-control" formControlName="endDate" />
            <span class="text-danger" *ngIf="this.form.get('endDate').errors">
              Invalid date
            </span>
          </div>
        </div>
      </div>
      <p class="italic">* Results include laundry dropoffs on all days of the week.</p>
      
      <div class="inline-checkbox" *ngIf="!hasUser">
        <div class="checkbox check-primary">
          <input type="checkbox" value="1" id="maleCheckbox" formControlName="maleFilter" (change)="parseResponse()">
          <label for="maleCheckbox">Male</label>
        </div>
        
        <div class="checkbox check-primary">
          <input type="checkbox" value="1" id="femaleCheckbox" formControlName="femaleFilter" (change)="parseResponse()">
          <label for="femaleCheckbox">Female</label>
        </div>
        
        <div class="checkbox check-primary">
          <input type="checkbox" value="1" id="staffCheckbox" formControlName="staffFilter" (change)="parseResponse()">
          <label for="staffCheckbox">Staff</label>
        </div>
      </div>
      
      <div class="row">
        <div class="col">
          <div class="text-right">
            <button type="button" class="btn btn-success" [disabled]="form.invalid" (click)="createPDF()" *ngIf="!hasUser">Gen. PDF</button>
            <button type="button" class="btn btn-success" [disabled]="form.invalid" (click)="createExcel()" *ngIf="!hasUser">Gen. Excel</button>
            <button type="submit" class="btn btn-primary" [disabled]="form.invalid">Submit</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- END CARD -->

<div class="row">
  <div class="col-md-3 col-sm-6" *ngIf="!hasUser">
    <!-- START WIDGET widget_weekly_sales_card-->
    <div class="card no-border widget-loader-bar m-b-10">
      <div class="container-xs-height full-height">
        <div class="row-xs-height">
          <div class="col-xs-height col-top">
            <div class="card-header  top-left top-right">
              <div class="card-title">
                <span class="font-montserrat fs-11 all-caps"> 
                  Total Users 
                </span>
              </div>
              <div class="card-controls">
                <ul>
                  <li><a href="#" class="portlet-refresh text-black" data-toggle="refresh"><i class="portlet-icon portlet-icon-refresh"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row-xs-height">
          <div class="col-xs-height col-top">
            <div class="p-l-20 p-t-50 p-b-20 p-r-20">
              <h3 class="no-margin p-b-5">{{numPayableUsers}} Payable User(s)</h3>
              <div *ngIf="dates">{{dates?.startDate | date : 'dd MMM yyyy'}} - {{dates?.endDate | date : 'dd MMM yyyy'}}</div>
              <span class="small hint-text">{{total.orders}} Payable Order(s)</span>   
            </div>
          </div>
        </div>
        <div class="row-xs-height">
          <div class="col-xs-height col-bottom">
            <div class="progress progress-small m-b-0">
              <!-- START BOOTSTRAP PROGRESS (http://getbootstrap.com/components/#progress) -->
              <div class="progress-bar progress-bar-complete" style="width:100%"></div>
              <!-- END BOOTSTRAP PROGRESS -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END WIDGET --></div>
    
    <div [ngClass]="{'col-md-3 col-sm-6': !hasUser, 'col-md-4': hasUser}">
      <!-- START WIDGET widget_weekly_sales_card-->
      <div class="card no-border widget-loader-bar m-b-10">
        <div class="container-xs-height full-height">
          <div class="row-xs-height">
            <div class="col-xs-height col-top">
              <div class="card-header  top-left top-right">
                <div class="card-title">
                  <span class="font-montserrat fs-11 all-caps">
                    Collected Quantities
                  </span>
                </div>
                <div class="card-controls">
                  <ul>
                    <li><a href="#" class="portlet-refresh text-black" data-toggle="refresh"><i class="portlet-icon portlet-icon-refresh"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row-xs-height">
            <div class="col-xs-height col-top">
              <div class="p-l-20 p-t-50 p-b-20 p-r-20">
                <h3 class="no-margin p-b-5">{{collected.quantity}} Piece(s)</h3>
                <div *ngIf="dates">{{dates?.startDate | date : 'dd MMM yyyy'}} - {{dates?.endDate | date : 'dd MMM yyyy'}}</div>
                <span class="small hint-text">{{collected.orders}} Order(s)</span>                
              </div>
            </div>
          </div>
          <div class="row-xs-height">
            <div class="col-xs-height col-bottom">
              <div class="progress progress-small m-b-0">
                <!-- START BOOTSTRAP PROGRESS (http://getbootstrap.com/components/#progress) -->
                <div class="progress-bar progress-bar-success" style="width:100%"></div>
                <!-- END BOOTSTRAP PROGRESS -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END WIDGET -->
    </div>
    <div [ngClass]="{'col-md-3 col-sm-6': !hasUser, 'col-md-4': hasUser}">
      <!-- START WIDGET widget_weekly_sales_card-->
      <div class="card no-border widget-loader-bar m-b-10">
        <div class="container-xs-height full-height">
          <div class="row-xs-height">
            <div class="col-xs-height col-top">
              <div class="card-header  top-left top-right">
                <div class="card-title">
                  <span class="font-montserrat fs-11 all-caps">
                    Pending Quantities
                  </span>
                </div>
                <div class="card-controls">
                  <ul>
                    <li><a href="#" class="portlet-refresh text-black" data-toggle="refresh"><i class="portlet-icon portlet-icon-refresh"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row-xs-height">
            <div class="col-xs-height col-top">
              <div class="p-l-20 p-t-50 p-b-20 p-r-20">
                <h3 class="no-margin p-b-5">{{pending.quantity}} Piece(s)</h3>
                <div *ngIf="dates">{{dates?.startDate | date : 'dd MMM yyyy'}} - {{dates?.endDate | date : 'dd MMM yyyy'}}</div>
                <span class="small hint-text">{{pending.orders}} Order(s)</span>   
              </div>
            </div>
          </div>
          <div class="row-xs-height">
            <div class="col-xs-height col-bottom">
              <div class="progress progress-small m-b-0">
                <!-- START BOOTSTRAP PROGRESS (http://getbootstrap.com/components/#progress) -->
                <div class="progress-bar progress-bar-warning" style="width:100%"></div>
                <!-- END BOOTSTRAP PROGRESS -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END WIDGET -->
    </div>
    
    <div [ngClass]="{'col-md-3 col-sm-6': !hasUser, 'col-md-4': hasUser}">
      <!-- START WIDGET widget_weekly_sales_card-->
      <div class="card no-border widget-loader-bar m-b-10">
        <div class="container-xs-height full-height">
          <div class="row-xs-height">
            <div class="col-xs-height col-top">
              <div class="card-header  top-left top-right">
                <div class="card-title">
                  <span class="font-montserrat fs-11 all-caps"> 
                    Total Rejected/Voided
                  </span>
                </div>
                <div class="card-controls">
                  <ul>
                    <li><a href="#" class="portlet-refresh text-black" data-toggle="refresh"><i class="portlet-icon portlet-icon-refresh"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row-xs-height">
            <div class="col-xs-height col-top">
              <div class="p-l-20 p-t-50 p-b-20 p-r-20">
                <h3 class="no-margin p-b-5">{{rejectedAndVoided.quantity}} Piece(s)</h3>
                <div *ngIf="dates">{{dates?.startDate | date : 'dd MMM yyyy'}} - {{dates?.endDate | date : 'dd MMM yyyy'}}</div>
                <span class="small hint-text">{{rejectedAndVoided.orders}} Order(s)</span>   
              </div>
            </div>
          </div>
          <div class="row-xs-height">
            <div class="col-xs-height col-bottom">
              <div class="progress progress-small m-b-0">
                <!-- START BOOTSTRAP PROGRESS (http://getbootstrap.com/components/#progress) -->
                <div class="progress-bar progress-bar-primary" style="width:100%"></div>
                <!-- END BOOTSTRAP PROGRESS -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END WIDGET -->
    </div>
    
  </div>
  <!-- END WIDGET ROW -->
  
  
  
  <!-- START CARD -->
  <div data-pages="card" class="card card-default">
    <div class="card-header  ">
      <div class="card-title">
        Reports <span *ngIf="dates">({{dates?.startDate | date : 'dd MMM yyyy'}} - {{dates?.endDate | date : 'dd MMM yyyy'}})</span>
      </div>
      <div class="card-controls">
        <ul>
          <li>
            
          </li>
        </ul>
      </div>
    </div>
    <div class="card-block">
      <div class="m-t-20">        
        <table class="table table-condensed" datatable [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" style="width:100%">
          <!--<thead>
            <tr>
              <th>Dropoff Date</th>
              <th>Collected Date</th>
              <th>Order ID</th>
              <th>Submitted By</th>
              <th>Qty</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let order of orders">
              <td [attr.data-sort]="order.dropOffDate">
                {{order.dropOffDate | date : 'dd MMM yyyy h:mm a (EEE)'}}
              </td>
              <td [attr.data-sort]="order.collectedDate">
                {{ (order.collectedDate | date : 'dd MMM yyyy h:mm a (EEE)') || 'Not Collected'}}
              </td>
              <td>{{order.idOrder}}</td>
              <td>
                <a routerLink="/users/report/{{order.id}}">
                  {{order.id}}
                </a>
              </td>
              <td>
                <a class="cursor text-primary" (click)="onShowOrderDetails(order)">
                  {{order.quantity}}
                </a>
              </td>
              <td class="text-center">
                <button class="btn btn-tag btn-tag-rounded status-btn"
                [ngClass]="{
                  'bg-warning': order.status === 'PENDING',
                  'bg-success': order.status === 'COLLECTED',
                  'bg-danger': (order.status === 'VOIDED' || order.status === 'REJECTED')
                }" (click)="onShowOrderDetails(order)"> 
                {{order.status}}
              </button>
            </td>
            <td class="no-overflow">
              <ng-container *ngIf="(order.status !== 'VOIDED' && order.status !== 'REJECTED'); else viewReason">
                <div class="dropdown dropdown-default">
                  <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Actions
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" (click)="onMarkAsCollected(order)" *ngIf="order.status === 'PENDING'">Mark Collected</a>
                    <a class="dropdown-item" (click)="onVoidOrder(order)">Void Order</a>
                    <a class="dropdown-item" (click)="onRejectOrder(order)">Reject Order</a>
                  </div>
                </div>
              </ng-container>
              <ng-template #viewReason>
                <a class="cursor m-b-5 text-primary" (click)="onShowOrderDetails(order)">
                  <i class="fa fa-eye"></i> Details
                </a>
              </ng-template>
            </td>
          </tr>
        </tbody>-->
      </table>
    </div>
  </div>
</div>
<!-- END CARD -->

<!-- ORDER DETAILS -->
<ng-template #detailsModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Order #{{orderDetails?.idOrder}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>Laundry  ID: {{orderDetails?.idUser}}</div>
    <div>Dropoff Date: {{orderDetails?.dropOffDate | date : 'dd MMM yyyy h:mm a (EEE)'}}</div>
    <div>Collected Date: {{ (orderDetails?.collectedDate | date : 'dd MMM yyyy h:mm a (EEE)') || 'Not Collected'}}</div>
    <div>Status: 
      <span class="bold" [ngClass]="{'text-danger': (orderDetails?.status === 'VOIDED' || orderDetails?.status === 'REJECTED')}">
        {{ orderDetails?.status | titlecase }}
      </span>
    </div>
    <div *ngIf="orderDetails?.status === 'VOIDED' || orderDetails?.status === 'REJECTED'">
      <ng-container *ngIf="orderDetails?.status === 'REJECTED'; else voidReason">
        <div>Rejected Reason: {{ orderDetails?.rejectedReason || 'No Reason Provided'}}</div>
        <div>Rejected By: {{ orderDetails?.doneBy }}</div>
      </ng-container>
      <ng-template #voidReason>
        <div>Voided Reason: {{ orderDetails?.voidReason || 'No Reason Provided'}}</div>    
        <div>Voided By: {{ orderDetails?.doneBy }}</div>
      </ng-template>
    </div>
    
    <table class="table table-condensed m-t-20" style="width:100%">
      <thead>
        <tr>
          <th>Product</th>
          <th>Quantity</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let product of orderDetails?.products">
          <td>
            {{product.name}} <span *ngIf="product.chineseName">({{product.chineseName}})</span>
          </td>
          <td class="text-center">
            {{product.quantity}}
          </td>
        </tr>
      </tbody>
    </table>
    
    <div class="m-t-30">Total: {{orderDetails?.quantity}}</div>  
    
    <div class="m-t-30 text-right">
      <ng-container *ngIf="orderDetails?.status !== 'VOIDED' && orderDetails?.status !== 'REJECTED'">
        <button class="btn btn-danger" (click)="onVoidOrder(orderDetails)">Void Order</button>
        <button class="btn btn-danger" (click)="onRejectOrder(orderDetails)">Reject Order</button>
      </ng-container>
      <button class="btn btn-success" (click)="onMarkAsCollected(orderDetails)" *ngIf="orderDetails?.status === 'PENDING'">Mark Collected</button>
    </div>
  </div>
</ng-template>


<!-- VOID -->
<ng-template #voidModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Void Order #{{orderDetails?.idOrder}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>Are you sure you want to void 
      <span class="bold text-danger">Order #{{orderDetails?.idOrder}}?</span>
    </div>
    
    <table class="table table-condensed m-t-20" style="width:100%">
      <thead>
        <tr>
          <th>Product</th>
          <th>Quantity</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let product of orderDetails?.products">
          <td>
            {{product.name}} <span *ngIf="product.chineseName">({{product.chineseName}})</span>
          </td>
          <td class="text-center">
            {{product.quantity}}
          </td>
        </tr>
      </tbody>
    </table>
    
    <div class="m-t-30">Total: {{orderDetails?.quantity}}</div>  
    
    <div class="form-group m-t-20 m-b-10">
      <label class="control-label">Enter Void Reason (if any)</label>
      <input type="text" class="form-control" [(ngModel)]="orderDetails.reason">
    </div>
    
    <div class="text-right">
      <button class="btn btn-danger" (click)="voidOrder()">Void Order</button>
    </div>
  </div>
</ng-template>

<!-- REJECT -->
<ng-template #rejectModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Reject Order #{{orderDetails?.idOrder}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>Are you sure you want to reject 
      <span class="bold text-danger">Order #{{orderDetails?.idOrder}}?</span>
    </div>
    
    <table class="table table-condensed m-t-20" style="width:100%">
      <thead>
        <tr>
          <th>Product</th>
          <th>Quantity</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let product of orderDetails?.products">
          <td>
            {{product.name}} <span *ngIf="product.chineseName">({{product.chineseName}})</span>
          </td>
          <td class="text-center">
            {{product.quantity}}
          </td>
        </tr>
      </tbody>
    </table>
    
    <div class="m-t-30">Total: {{orderDetails?.quantity}}</div>  
    
    <div class="form-group m-t-20 m-b-10">
      <label class="control-label">Enter Void Reason (if any)</label>
      <input type="text" class="form-control" [(ngModel)]="orderDetails.reason">
    </div>
    
    <div class="text-right">
      <button class="btn btn-danger" (click)="rejectOrder()">Reject Order</button>
    </div>
  </div>
</ng-template>

<!-- COLLECT -->
<ng-template #collectModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Collect Order #{{orderDetails?.idOrder}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>Are you sure you want to collect 
      <span class="bold text-danger">Order #{{orderDetails?.idOrder}}?</span>
    </div>
    
    <table class="table table-condensed m-t-20" style="width:100%">
      <thead>
        <tr>
          <th>Product</th>
          <th>Quantity</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let product of orderDetails?.products">
          <td>
            {{product.name}} <span *ngIf="product.chineseName">({{product.chineseName}})</span>
          </td>
          <td class="text-center">
            {{product.quantity}}
          </td>
        </tr>
      </tbody>
    </table>
    
    <div class="m-t-30">Total: {{orderDetails?.quantity}}</div>  
    
    <div class="form-group m-t-20 m-b-10">
      <label class="control-label">Collection Date</label>
      <input type="date" class="form-control" [(ngModel)]="orderDetails.collectedDate">
    </div>
    
    <div class="text-right">
      <button class="btn btn-success" (click)="collectOrder()">Collect Order</button>
    </div>
  </div>
</ng-template>