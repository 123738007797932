<div data-pages="parallax">
  <div class="container no-padding container-fixed-lg">
    <div class="inner">
      <!-- START BREADCRUMB -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/reports">Reports</a></li>
        <li class="breadcrumb-item active">List All</li>
      </ol>
    </div>
  </div>
</div>

<div class="container no-padding">
  <app-report></app-report>
</div>
