<div data-pages="parallax">
  <div class="container no-padding container-fixed-lg">
    <div class="inner">
      <!-- START BREADCRUMB -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/products">Products</a></li>
        <li class="breadcrumb-item active">{{formType | titlecase}}</li>
      </ol>
    </div>
  </div>
</div>

<div class="container no-padding">
  <!-- START CARD -->
  <div data-pages="card" class="card card-default">
    <div class="card-header">
      <div class="card-title">
          {{formType | titlecase}} Product
      </div>
      <div class="card-controls">
        <ul>
        </ul>
      </div>
    </div>
    <div class="card-block">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group required">
              <label class="control-label">Product Name</label>
              <input type="text" class="form-control" [(ngModel)]="productName" />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group required">
              <label class="control-label">Product Chinese Name</label>
              <input type="text" class="form-control" [(ngModel)]="productChineseName" />
            </div>
          </div>
        </div>
        
        <div class="form-group">
          <label class="control-label">Product Image</label> 
          
          <div class="row">
            <div class="col-sm-6" *ngIf="previewImage">
              <div class="image-preview">
                <img [src]="previewImage" class="img-fluid" />
              </div>
            </div>
            <div class="col">
              <div 
                ng2FileDrop class="file-drop"
                (ng2FileDropFileAccepted)="onDropUploadFile($event)"
                (click)="standardFileInput.click()"
              >
                <div class="content">
                  Drop files <span class="medium">to upload</span>
                  <span class="hint">or click here</span>
                </div>
              </div>
              <input type="file" accept="image/*" style="visibility: hidden" (change)="onClickUploadFile($event)" #standardFileInput/>
          </div>
        </div>

        <div class="text-danger m-t-5" *ngIf="imageError">
          {{imageError}}
        </div>

      </div>
  </div>
</div>
<!-- END CARD -->

<div class="m-t-10 text-right">
  <button class="btn btn-success" (click)="onSubmit()">{{formType | titlecase}} Product</button>
</div>
</div>

