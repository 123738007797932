<div data-pages="parallax">
  <div class="container no-padding container-fixed-lg">
    <div class="inner">
      <!-- START BREADCRUMB -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/users">Users</a>
        </li>
        <li class="breadcrumb-item active">Report</li>
      </ol>
    </div>
  </div>
</div>

<div class="container no-padding">
  <!-- START CARD -->
  <div data-pages="card" class="card card-default">
    <div class="card-header">
      <div class="card-title">
        User Details
      </div>
      <div class="card-controls">
        <ul>
          <li>
            <button class="btn btn-sm btn-success" routerLink="/users/edit/{{userDetails?.id}}">Edit</button>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-block">
      Laundry ID: {{userDetails?.id}} <br/>
      Account Type: {{userDetails?.type}} <br/>
      Activated?: {{userDetails?.isAvailable ? 'Yes' : 'No'}}
    </div>
    
  </div>
  <!-- END CARD -->
  <app-report [idUser]="userDetails?.idUser" [hasUser]="true"></app-report>
</div>
